$dark_color: #000000;
$overlay-color: #FFFFFF;
$body-color: #370A3E;
$emphasis-color: #46BCE3;
// $body-color: #46BCE3;
// $emphasis-color: #370a3e;
// $body-color: #9C453A;
// $emphasis-color: #46BCE3;

$text-large: 60pt;
$text-medium: 40pt;
$text-small: 20pt;
$text-xsmall: 16pt;

$margin-medium: 6rem;
$margin-small: 2rem;
$margin-xsmall: 1rem;

$break-medium: 650pt;
$break-small: 400pt;

$grid-width: 300pt;

%large-text {
  font-size: $text-large;
  @media not all and (min-width: $break-medium) {
    font-size: $text-medium;
  }
  @media not all and (min-width: $break-small) {
    font-size: $text-small;
  }
  font-weight: 700;
}

%medium-text {
  font-weight: 600;
  @media not all and (min-width: $break-medium) {
    font-size: $text-small;
  }
  font-size: 35pt;
}

%small-text {
  font-weight: 600;
  @media not all and (min-width: $break-medium) {
    font-size: $text-xsmall;
  }
  font-size: $text-small;
}

body {
  background: $body-color;
}

a {
  color: $emphasis-color;
}

h3 {
  margin-top: 0pt;
}

.content-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-body {
  flex: 1;
}

.faq {
  .question {
    @extend %small-text;
    color: $emphasis-color;
  }

  .answer {
    @extend %small-text;
  }

  dt {
    &:not(:first-child) {
      padding-top: $margin-small;
    }
  }

}

.header {
  border-bottom : 1px white solid;
  width: 100%;
  background: $dark_color;
  display: flex;
  justify-content: space-between;
  padding-left: $margin-medium;
  padding-right: $margin-medium;
  @media not all and (min-width: $break-small) {
    padding-left: $margin-small;
    padding-right: $margin-small;
  }
  box-sizing: border-box;

  .header-group {
    margin-top: .8rem;
    margin-bottom: .8rem;
    > *:not(:first-child) {
      @media not all and (min-width: $break-small) {
        margin-left: auto;
        margin-right: auto;
      }
      margin-left: 4rem;
    }
    &.menu-items.big {
      @media not all and (min-width: $break-medium) {
        display: none;
      }
    }
    &.menu-items.small {
      @media (min-width: $break-medium) {
        display: none;
      }
    }
  }

}

.header-item {
  color: $overlay-color;
  font-weight: 700;
  font-size: 16pt;
  margin: 0;
  @media not all and (min-width: $break-small) {
    margin-left: auto;
    margin-right: auto;
  }
  &.emphasis {
    border: 1pt solid $overlay-color;
    padding: 3pt 10pt;
    border-radius: 3pt;
    text-decoration: none;
    
    &:hover {
      background-color: $body-color;
      color: $overlay-color;
      border-color: $overlay-color;
    }
  }

}

button.header-item {
  border: none;
  background-color: rgba(0,0,0,0);
  cursor: pointer;
  text-decoration: none;
}

.overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: $dark_color;
  z-index: 100;
  left: 0;
  top: 0;

  .close-overlay {
    position: absolute;
    top: $margin-xsmall;
    right: $margin-small;
    -webkit-appearance: none;
    border: none;
    background-color: rgba(0,0,0,0);
    @extend %medium-text;
    color: $overlay-color;
    cursor: pointer;
  }

  .overlay-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .header-item {
      margin-left: auto;
      margin-right: auto;
      font-size: 20pt;
    }

    > *:not(:first-child) {
      margin-top: 3rem;
    }
  }
}

.hero {
  position: relative;
  height: 50vw;
  max-height: 50vh;
  border-bottom: 1px white solid;
  @media (min-width: $break-medium) {
    min-height: 300pt;
  }
  > div {
    position: absolute;
    height: 100%;
  }
  > .bg-fill-left {
    background-image: url(./images/hero-left.jpg);
    @media not all and (min-width: $break-medium) {
      width: 50%;
    }
    background-size: 70% 110%;
    width: 70%;
  }
  > .bg-fill-right{
    background-image: url(./images/hero-right.jpg);
    background-size: 50% 110%;
    @media not all and (min-width: $break-medium) {
      width: 50%;
      left: 50%;
    }
    width: 30%;
    left: 70%;
  }
  > .image {
    background-image: url(./images/hero.jpg);
    background-repeat: no-repeat;
    background-size: auto 110%;
    background-position: top left 100%;
    @media not all and (min-width: $break-medium) {
      background-position: top left 0%;
    }
    width: 100%;
  }
  > .gradient {
    background-image: linear-gradient(rgba(0, 0, 0, .2) 70%, rgba(0, 0, 0, .5));
    width: 100%;
  }

  > .filler {
    position: static;
    height: 100%;
    width: 100%;
    @media (min-width: $break-medium) {
      height: 0%;
    }
  }

}

.hero-text {
  padding-left: $margin-medium;
  padding-right: $margin-medium;
  &.big {
    max-width: 40%;
    width: calc(min(50%, 700px));
    position: absolute;
    bottom: $margin-small;
    height: auto;
    @media not all and (min-width: $break-medium) {
      display: none;
    }
  }
  &.small {
    width: auto;
    text-align: center;
    position: static;
    background-color: rgba(0, 0, 0 , .7);
    border-bottom: 1px white solid;
    @media not all and (min-width: $break-small) {
      padding: $margin-xsmall $margin-xsmall;
    }
    @media (min-width: $break-medium) {
      display: none;
    }
    > .logo {
      max-width: 70%;
      height: 35pt;
      @media not all and (min-width: $break-small) {
        height: 18pt;
      }
    }
  }
  > .logo {
    max-width: 70%;
  }
  height: auto;
  color: $overlay-color;
  @extend %large-text;
}

.container {
  @media (min-width: $break-medium) {
    margin-left: $margin-medium;
    margin-right: $margin-medium;
  }
  margin-left: $margin-small;
  margin-right: $margin-small;
  margin-top: $margin-small;
  margin-bottom: $margin-small;

  box-sizing: border-box;
  
  @extend %medium-text;
  color: $overlay-color;

  em {
    color: $emphasis-color;
  }
  flex: 1;
}

.inline-container {
  font-weight: 500;
  width: 100%;
  max-width: $grid-width;
  padding: none;
  margin: none;
  color: $overlay-color;

  @extend %small-text;

  em {
    color: $emphasis-color;
  }
}

.center-content {
  text-align: center;
}

.center {
  margin: auto;
  width: auto;
  max-width: 800pt;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.body-text {
  max-width: 550pt;
  margin: auto;
  @extend %small-text;
}

.bullet-container {
  color: $emphasis-color;
  text-align: center;
  letter-spacing: 10pt;
  .bullet {
    height: 20pt;
  }
}

ul {
  > *:not(:last-child) {
    margin-bottom: 1rem;
  }
}

input[type='submit'] {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0px;
  background: $emphasis-color;
  padding: 6pt;
  border: none;
  color: $overlay-color;
  &:hover {
    background: darken($emphasis-color, 10%);
  }
  &:active {
    background: darken($emphasis-color, 20%);
  }
  &:disabled {
    background: lighten($emphasis-color, 20%);
  }
}

input[type='email'] {
  border-radius: 0px;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  padding: 6pt;
  box-sizing: border-box;
}

.form-element {
  font-weight: 700;
  font-size: 20pt;
  @media not all and (min-width: $break-medium) {
    width: 100%;
    min-width: 0pt;
  }
  min-width: $grid-width;

}

h1.form-element, p.form-element, label.form-element {
  color: $overlay-color;
}

.vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.labeled-element {
  display: flex;
  align-items: center;
  flex-direction: column;
  @media not all and (min-width: $break-medium) {
    width: 100%;
  }
  *:not(:last-child) {
    margin-bottom: 0rem;
  }
}

.signup {
  color: $overlay-color;
  background: transparentize($dark-color, .6);
  padding-bottom: $margin-small;
  @media not all and (min-width: $break-medium) {
    margin-left: 0rem;
    margin-right: 0rem;
    padding-left: $margin-small;
    padding-right: $margin-small;
  }
  padding-left: $margin-medium;
  padding-right: $margin-medium;
  box-sizing: border-box;
}

form *:not(:last-child) {
  margin-bottom: 2rem;
}

.faq {
  color: $overlay-color;
}

footer {
  border-top: 1px white solid;
  color: $overlay-color;
  text-align: center;
  background: $dark_color;
  padding-top: $margin-small;
  padding-bottom: $margin-small;

  a {
    color: $overlay-color;
  }
}

.disclaimer {
  border: 1px $overlay-color solid;
  padding: $margin-xsmall;
  display: inline-block;
  margin-bottom: $margin-xsmall
}

hr {
  @media not all and (min-width: $break-small) {
    margin-left: $margin-small;
    margin-right: $margin-small;
  }
  margin-left: $margin-medium;
  margin-right: $margin-medium;
  height: 1px;
  border: none;
  background-color: $overlay-color;
}

.contact {
  text-align: left;
  margin-left: $margin-medium;
  margin-right: $margin-medium;
  @media not all and (min-width: $break-small) {
    margin-left: $margin-small;
    margin-right: $margin-small;
  }
  margin-top: $margin-small;
}

.episode {
  & > h2 {
    @extend %medium-text;
  }

  &:not(:first-child) {
    margin-top: $margin-medium;
  }
}

.hidden {
  visibility: hidden;
}

@keyframes fadeIn{
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.fading-enter {
  opacity: 0;
}
.fading-enter-active {
  opacity: 1;
  transition: opacity 200ms, transform 200ms;
}
.fading-exit {
  opacity: 1;
}
.fading-exit-active {
  opacity: 0;
  transition: opacity 200ms, transform 200ms;
}
